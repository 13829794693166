<template>
  <div>
    <link
      rel="preconnect"
      href="https://fonts.gstatic.com"
    >
    <link
      href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap"
      rel="stylesheet"
    >
    <link
      href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap"
      rel="stylesheet"
    >

    <header class="header">
      <div class="container header__container">
        <div class="qr-code">
          <div class="qr-code__icon">
            <img
              :src="require('../assets/images/qr.svg')" width="96" height="96"
              border="0"
              title="QR код">
          </div>
        </div>
        <div class="info">
          <img
            class="info__icon"
            :src="require('../assets/terminal/images/dest/icon_info.png')"
            alt="info icon"
          >
          <div class="info-list">
            <h3 class="info-list__title">
              Скачайте наше приложение
            </h3>
            <p class="info-list__subtitle">
              и записывайтесь на стрижку в пару кликов
            </p>
          </div>
        </div>
        <div class="header__wrap">
          <img
            class="logo"
            :class="{'hide-logo': $router.currentRoute.name === 'Home'}"
            :src="require('../assets/terminal/images/dest/logo.png')"
            alt="logo"
          >
          <div
            class="button-header"
            @click="toMain"
            :class="{'hide-button': $router.currentRoute.name === 'Home'}"
          >
            на главный экран
          </div>
        </div>
      </div>
    </header>

    <router-view/>


  </div>
</template>

<script>
import cookie from 'js-cookie';

export default {
  name: "TerminalLayout",

  data() {
    return {
      timer: null,
      interval: null,
    }
  },

  watch: {
    $route() {
      this.backHome();
    },
  },

  methods: {
    toMain() {
      if (this.$route.name === 'Done') {
        location.href = '/terminal/home';
        localStorage.removeItem('phoneClient');
        localStorage.removeItem('emailClient');
        return;
      }
      this.$router.push('/terminal/home');
      localStorage.removeItem('phoneClient');
      localStorage.removeItem('emailClient');
    },

    backHome() {
      clearTimeout(this.timer);
      if (process.env.NODE_ENV === 'development')
        return; // отключено
      const page = this.$route.name;
      if (['Home', 'Done', 'ChoosePayMethod', 'PayByCash', 'PayByCard', 'Products'].includes(page))
        return;
      let seconds = page === 'Hello' ? 30 : 60;
      if (['ChoosePayMethod', 'PayByCash', 'PayByCard'].includes(page))
        seconds = 90;
      if (['Products'].includes(page))
        seconds = 180;
      this.timer = setTimeout(() => {
        this.$router.push('/terminal/home');
        localStorage.removeItem('phoneClient');
        localStorage.removeItem('emailClient');
      }, seconds * 1000)
    },

    closeShift() {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        const currentTime = (new Date).getHours();
        const closeTime = 22;
        if (currentTime >= closeTime && !localStorage.getItem('terminal_shift_closed')) {
          localStorage.setItem('terminal_shift_closed', 1);
          console.log('closing shift');
          //KkmServer.CloseShift(0, 'Открывальщик О.О.', '430601071197').Execute(() => {});
        }

        if (currentTime < closeTime && localStorage.getItem('terminal_shift_closed')) {
          localStorage.removeItem('terminal_shift_closed');
        }
      }, 900000);
    }
  },

  created() {
    this.backHome();
    this.closeShift();
    setInterval(() => {
      if (this.$route.name === 'Home')
        location.reload();
    }, 300000);

    if (!JSON.parse(cookie.get('user')).user_data.roles.find(role => role.slug === 'terminal'))
      location.href = '/authorization'
  }
}
</script>

<style>
@import "../assets/terminal/css/app.min.css";

</style>

